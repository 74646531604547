<template>
    <div class="time-picker" @click="changePickVisible">
        <div class="placeholder">
            <span class="default" v-if="!defaultValue && !timeValue">请点击选择</span>
            <span class="value" v-else>{{timeValue || defaultValue  }}</span>
        </div>
        <img src="@/assets/images/common/select.png" alt="" />
    </div>
    <van-popup v-model:show="pickerVisible" round position="bottom">
        <van-datetime-picker
            v-model="currentDate"
            :title="title"
            type="datetime"
            @confirm="onConfirm"
            @cancel="changePickVisible"
        />
    </van-popup>
</template>
<script>
import { DatetimePicker, Popup } from 'vant';
import dayjs from 'dayjs';
import { DATE_TIME_FORMATE } from '@/constants';
import {ref, watchEffect} from 'vue';
export default {
    name: 'TimePicker',
    components: {
        [DatetimePicker.name]: DatetimePicker,
        [Popup.name]: Popup,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        defaultValue: String,
    },
    emits: ['selectConfirm'],
    setup(props, { emit }) {
        const currentDate = ref(new Date());
        const timeValue = ref('');
        const changePickVisible = () => {
            pickerVisible.value = !pickerVisible.value;
        };
        const onConfirm = (value) => {
            changePickVisible();
            timeValue.value = dayjs(value).format(DATE_TIME_FORMATE);
            emit('selectConfirm', { type: props.type, value });
        };
        const pickerVisible = ref(false);
        watchEffect(() => {
            if (props.defaultValue) {
                currentDate.value  = new Date(props.defaultValue)
            }
        });
        return {
            onConfirm,
            pickerVisible,
            changePickVisible,
            currentDate,
            timeValue,
        };
    },
};
</script>
<style lang="less" scoped>
.time-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 8px;
    box-sizing: border-box;
    .placeholder {
        font-size: 13px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #999999;
        /*.default {*/
        /*    font-size: 13px;*/
        /*    font-family: PingFang SC,serif;*/
        /*    font-weight: 400;*/
        /*    color: #999999;*/
        /*}*/
        /*.value {*/
        /*    font-size: 14px;*/
        /*    font-family: PingFang SC,serif;*/
        /*    font-weight: bold;*/
        /*    color: #333333;*/
        /*}*/
    }
    img {
        width: 10px;
    }
}
</style>
